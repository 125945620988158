@keyframes slide-in-left {
  0% {
    transform: translateX(-200px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    transform: translateX(200px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
.yellowBlur {
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: var(--color-goldenrod-200);
  filter: blur(364px);
  width: 458px;
  height: 452px;
  z-index: -20;
}
.regularHeroText {
  margin: 0;
  position: absolute;
  top: 45%;
  left: 15%;
  transform: translate(-50%, -50%);
  display: inline-block;
  width: 354px;
  height: 22px;
  opacity: 0;
}
.regularHeroText.animate {
  animation: 1s ease 0s 1 normal forwards slide-in-left;
}

.heroLogoIcon {
  position: absolute;
  top: 5%;
  left: 55%;
  transform: translate(-50%, -50%);
  border-radius: var(--br-22xl);
  width: 589px;
  height: 561px;
  object-fit: cover;
  opacity: 0;
}
.heroLogoIcon.animate {
  animation: 1s ease 0s 1 normal forwards slide-in-right;
}

.boldHeroText {
  margin: 0;
  position: absolute;
  top: 25%;
  left: 15%;
  font-size: var(--h1-main-heading-size);
  font-weight: 700;
  font-family: var(--h1-main-heading);
  text-align: left;
  display: inline-block;
  width: 732px;
  height: 151px;
  opacity: 0;
}
.boldHeroText.animate {
  animation: 1s ease 0s 1 normal forwards slide-in-left;
}

.hero {
  position: absolute;
  top: 136px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 813px;
  text-align: center;
  font-size: var(--p-body-text-size);
  color: var(--color-white);
  font-family: var(--p-body-text);
}

@media screen and (max-width: 1350px) {
  .regularHeroText {
    top: 95%;
    left: 20%;
    transform: translate(-50%, -50%);
  }

  .heroLogoIcon {
    top: 5%;
    left: 25%;
    transform: translate(-50%, -50%);
  }

  .boldHeroText {
    top: 75%;
    left: 20%;
    transform: translate(-50%, -50%);
    width: 70%;
  }
}

@media screen and (max-width: 970px) {
  .regularHeroText {
    top: 80%;
    left: 20%;
    transform: translate(-50%, -50%);
    font-size: 100%;
  }

  .heroLogoIcon {
    top: 5%;
    left: 20%;
    transform: translate(-50%, -50%);
  }

  .boldHeroText {
    top: 75%;
    left: 20%;
    transform: translate(-50%, -50%);
    width: 70%;
    font-size: 100%;
  }
}

@media screen and (max-width: 710px) {
  .regularHeroText {
    top: 57%;
    left: 15%;
    transform: translate(-50%, -50%);
    width: 70%;
    font-size: 85%;
  }

  .heroLogoIcon {
    top: 5%;
    left: 20%;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 40%;
  }

  .boldHeroText {
    top: 50%;
    left: 20%;
    transform: translate(-50%, -50%);
    width: 70%;
    font-size: 85%;
  }
}

@media screen and (max-width: 360px) {
  .regularHeroText {
    top: 40%;
    left: 15%;
    transform: translate(-50%, -50%);
    width: 70%;
    font-size: 70%;
  }

  .heroLogoIcon {
    top: 5%;
    left: 20%;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 25%;
  }

  .boldHeroText {
    top: 33%;
    left: 20%;
    transform: translate(-50%, -50%);
    width: 70%;
    font-size: 70%;
  }
}