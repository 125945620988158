body {
  margin: 0; /* Remove default margin */
  background-color: var(--color-darkslateblue); /* Set background color for the entire body */
}

.container {
  background-color: white; /* Set the background color for the container */
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  display: flex;
  align-items: center;
  justify-content: center;
}

.facebookIcon {
    position: relative;
    width: 262px;
    height: 77px;
    object-fit: cover;
  }
  .facebookButton,
  .githubButton {
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: var(--color-white);
    position: absolute;
    top: 248px;
    left: 119px;
    border-radius: var(--br-xl);
    width: 379px;
    height: 100px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .facebookButton:hover,
  .githubButton:hover,
  .googleButton:hover {
    background-color: var(--color-blanchedalmond);
  }
  .githubButton {
    top: 367px;
  }
  .chooseHowTo {
    position: absolute;
    top: 42px;
    left: 46px;
    display: inline-block;
    width: 525px;
    height: 59px;
  }
  .googleIcon {
    position: absolute;
    top: -50px;
    left: 89px;
    width: 200px;
    height: 200px;
    object-fit: cover;
  }
  .googleButton {
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: var(--color-white);
    position: absolute;
    top: 129px;
    left: 119px;
    border-radius: var(--br-xl);
    width: 379px;
    height: 100px;
    overflow: hidden;
  }
  .loginForm {
    background-color: var(--color-goldenrod-100);
    width: 617px;
    height: 488px;
    overflow: hidden;
    text-align: left;
    font-size: 40px;
    color: #000;
    font-family: var(--p-body-text);

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  