@keyframes slide-in-left {
  0% {
    transform: translateX(-200%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.products,
.account,
.shopify,
.contactUs,
.about {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: absolute;
  font-size: var(--font-size-xl);
  font-family: var(--p-body-text);
  color: var(--color-darkslateblue);
  text-align: left;
  display: inline-block;
  width: 100%;
  height: 15%;
}

.products {
  top: 15%;
  left: 5%;
}

.about {
  top: 25%;
  left: 5%;
}

.shopify {
  top: 35%;
  left: 5%;
}

.contactUs {
  top: 45%;
  left: 5%;
}

.account {
  top: 55%;
  left: 5%;
}

.drawerMenu {
  position: relative;
  background-color: var(--color-goldenrod-100);
  width: 45%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
  max-width: 90%;
}

.drawerMenu.animate {
  animation: 0.25s ease 0s 1 normal forwards slide-in-left;
}
