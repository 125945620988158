/* .mainPage {
  position: relative;
  background-color: var(--color-darkslateblue);
  width: 100%;
  height: 4289px;
  overflow: hidden;
} */

.mainPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: var(--color-darkslateblue);
  height: 4250px;
  overflow: hidden;
}

@media screen and (max-width: 1430px) {
  .mainPage {
    height: 5000px;
  }
}
