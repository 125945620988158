@keyframes fade-in-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.contactTitle {
  align-self: stretch;
  position: relative;
}

.titleFrame {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.telephone,
.email,
.linkedin {
  width: 30px;
  height: 30px;
  margin-right: 10px; /* Add space between links */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.phoneText,
.emailText,
.linkedinText {
  display: block;
  color: inherit;
  font-size: 30px;
  text-decoration: none;
  margin-right: 5%;
}

.telephone {
  background-image: url(/public/telephone@3x.png);
}

.email {
  background-image: url(/public/email@3x.png);
}

.linkedin {
  background-image: url(/public/linkedin@3x.png);
}

.innerFrame {
  align-self: stretch;
  position: relative;
  height: auto;
  overflow: hidden;
  flex-shrink: 0;
}

.footer,
.outerFrame {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative; /* Ensure the footer stays within the document flow */
}

.footer {
  position: relative;
  top: 45%;
  width: 100%;
  padding: 30px; /* Adjust padding */
  box-sizing: border-box;
  gap: 20px;
  opacity: 0;
  text-align: center;
  font-size: 50px;
  color: var(--color-white);
  font-family: Arial, sans-serif; /* Add font family */
}

.footer.animate {
  animation: 1s ease 0s 1 normal forwards fade-in-bottom;
}

.titleFrame,
.outerFrame,
.innerFrame {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1770px) {
  .footer {
    padding: 10px; /* Adjust padding */
  }
}

@media screen and (max-width: 1460px) {
  .footer {
    padding: 5px; /* Adjust padding */
  }
}

@media screen and (max-width: 1090px) {
  .phoneText,
  .emailText,
  .linkedinText {
    display: none;
  }

  .telephone,
  .email,
  .linkedin {
    width: 50px;
    height: 50px;
    margin-right: 30px;
  }

}

@media screen and (max-width: 1090px) {
  .footer {
    top: 44%;
  }
}

@media screen and (max-width: 710px) {
  .footer {
    top: 40%;
  }
}
