@keyframes slide-in-top {
  0% {
    transform: translateY(-200px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.aRam,
.navigationLogo,
.shopify,
.link {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  text-align: left;
  display: inline-block;
  flex-shrink: 0;
  text-decoration: none;
  text-underline-offset: none;
}

.navigationLogo {
  border-radius: var(--br-6xs);
  width: 77.2px;
  height: 70px;
  background-image: url(/public/navigation-logo@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.aRam {
  font-size: var(--font-size-xl);
  font-weight: 700;
  font-family: var(--p-body-text);
  color: var(--color-white);
  width: 75px;
  height: 26px;
}

.logoAndName {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-lg);
}

.horizontalList {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: var(--gap-lg);
}

.menuButton {
  cursor: pointer;
  width: 40px;
  height: 40px;
  background: none;
  border: none;
  display: none;
  margin: 15px;
  position: absolute;
  top: 10%;
  right: 0;
}

.menu {
  width: 595px;
  align-items: center;
  justify-content: space-between;
}

.menu,
.navbar,
.navigationBar {
  display: flex;
  flex-direction: row;
}

.navbar {
  flex: 1;
  height: 70px;
  align-items: center;
  justify-content: space-between;
  z-index: 20;
}

.navigationBar {
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  left: 13%;
  max-width: 75%;
  height: 134px;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-13xl) var(--padding-3xl);
  box-sizing: border-box;
  z-index: 10;
}

.navigationBar.animate {
  animation: 1s ease 0s 1 normal forwards slide-in-top;
}

.link {
  font-size: var(--font-size-xl);
  font-family: var(--p-body-text);
  color: var(--color-white);
  width: 85px;
  height: 21px;
  text-decoration: none;
  padding: 0 15px;
  box-sizing: border-box;
  white-space: nowrap;
}

.link:hover {
  text-decoration: none;
  color: var(--color-white);
}

@media screen and (max-width: 1200px) {
  .menuButton {
    display: none;
  }
}

@media screen and (max-width: 960px) {
  .horizontalList {
    display: none;
  }
  .menuButton {
    display: flex;
  }
  .navigationBar {
    padding: var(--padding-13xl) var(--padding-base);
  }
}

@media screen and (max-width: 420px) {
  .menuButton {
    display: flex;
  }
  .navbar {
    flex-direction: row;
    gap: var(--gap-0);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .navigationBar {
    padding-left: var(--padding-base);
    padding-right: var(--padding-base);
    box-sizing: border-box;
  }
}
